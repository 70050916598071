.foootermain{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 25px;
    height: 190px;
 
}
.foooter-desc{
    color: white;
    margin: 0;
}